#canvas {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1.5px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;

}

#canvasmovil {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1.5px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.dropzone {
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    min-width: 210px;
    margin-left: auto;
    margin-right: auto;
}

.files-ui-dropzone-children-container label::before {
    content: 'sube tu cédula aquí' !important;
    /* Texto en español que reemplazará al original */
    display: block !important;
    font-size: 15px !important;
    /* Asegúrate de que el contenido se muestre como un bloque */
    /* Añade cualquier otro estilo que desees aplicar a las letras en español */
}

.files-ui-dropzone-children-container label {
    font-size: 15px !important;
    /* Asegúrate de que el contenido se muestre como un bloque */
    /* Añade cualquier otro estilo que desees aplicar a las letras en español */
}

.files-ui-footer {
    display: none !important;
}

.spiner_loaging {
    width: 64px;
    height: 64px;
    position: relative;
    background: #FFF;
    border-radius: 4px;
    overflow: hidden;
}

.spiner_loaging:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    transform: rotate(45deg) translate(30%, 40%);
    background: #68cafe;
    box-shadow: 32px -34px 0 5px #0097e6;
    animation: slide 2s infinite ease-in-out alternate;
}

.spiner_loaging:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #0097e6;
    transform: rotate(0deg);
    transform-origin: 35px 145px;
    animation: rotate 2s infinite ease-in-out;
}

@keyframes slide {

    0%,
    100% {
        bottom: -35px
    }

    25%,
    75% {
        bottom: -2px
    }

    20%,
    80% {
        bottom: 2px
    }
}

@keyframes rotate {
    0% {
        transform: rotate(-15deg)
    }

    25%,
    75% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(25deg)
    }
}

a.disponible {
    background-color: #198754;
    cursor: pointer;
}

a.ocupado {
    background-color: #dc3545;
}

div.ocupado {
    background-color: #dc3545;
}

div.OCUPADO {
    background-color: #dc3545;
}

div.mesaselecion {
    background-color: #6c757d;
    cursor: pointer;
}

div.mesaocupado {
    background-color: #dc3545;
    cursor: not-allowed;
}

div.reservado {
    background-color: #ffc107;
    cursor: not-allowed;
}

div.mesadisponible {
    background-color: #198754;
    cursor: pointer;
}

div.mesareserva {
    background-color: #ffc107;
    cursor: not-allowed;
}

a.reservado {
    background-color: #ffc107;
    cursor: not-allowed;
}

a.seleccionado {
    background-color: #6c757d;
    cursor: pointer;
}

div.disponible {
    background-color: #198754;
    cursor: pointer;
}

div.seleccionado {
    background-color: #6c757d;
    cursor: pointer;
}

div.esquema {
    cursor: pointer;
}

div.esquema:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.4)
}

.btn-warning {
    color: rgb(247, 247, 247);
    background-color: #F58634 !important;
    border-color: #F58634 !important;

}

.btn-outline-danger {
    color: #F58634;
    border-color: #F58634 !important;
}

.btn-primary {
    color: #fff;
    background-color: #3F40E0 !important;
    border-color: #3F40E0 !important;
}

.text-pag {
    --bs-text-opacity: 1;
    color: rgba(var(#3F40E0), var(--bs-text-opacity)) !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #4655FC !important;
    border-color: #4655FC !important;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #b42e2e !important;
    border-color: #b42e2e !important;
}

.btn-warning:hover {
    color: #fff;
    background-color: #f57418 !important;
    border-color: #f57418 !important;
}

.pagos {
    transition: all .3s ease-in-out;
}

.pagos:hover {
    transform: scale(1.05);
    cursor: pointer;
}

li.agregados {
    cursor: pointer;
}

@media (min-width: 950px) {
    .modal-content {
        border-radius: 15px !important;
    }
}

@media (max-width: 967.98px) {
    .modal-dialog {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw !important;
        max-width: none !important;
        height: 100% !important;
        margin: 0 !important;
    }

    .modal-content {
        border-top: 20px !important;
    }

    .img-evento {
        height: 135px !important;
        width: 100% !important;
    }
}

/*
@media (min-width:780px){
    .img-evento{
        height: 280px;
       
     
    }
}*/

.modal-header {
    background-color: #212529 !important;
    color: white;
    border-top: 20px;
}

.toast {
    background-color: #212529 !important;
}

.toast-header {
    background-color: #212529 !important;
    color: white;
}

.close {
    color: white;
    transition: all .3s ease-in-out;
}

.close:hover {

    color: rgb(253, 252, 249);
}

.modal-backdrop.show {
    opacity: .1;
    filter: alpha(opacity=80);
    /* Para versiones anteriores de IE */
}

.modal-fullscreen {
    width: 100vw !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0 !important;
    border-radius: 0px !important;
}

.modal-fullscreen-md-down {
    padding: 0 !important;
}

.modal-fullscreen {
    padding: 0;
}

.link-light {
    cursor: pointer;
}




/*body {*/
/*scrollbar-width: thin;
    /* "auto" or "thin" */
/*  scrollbar-color: blue orange;
    /* scroll thumb and track */
/*}*/

/*
.section {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}
.section::-webkit-scrollbar {
    width: 12px;
}
.section::-webkit-scrollbar-track {
    background: orange;
}

.section::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
}

*/
/*div.seleccionmap{
   // background-color: #198754;
}*/