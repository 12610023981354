.cls-1 {
    opacity: .89;
}
/*
.cls-2 {
    fill: #f3eba2;
}*/

.cls-3 {
    opacity: .72;
}

.cls-4 {
    fill: #959696;
}

.cls-5 {
    opacity: 0;
}

.cls-6 {
    fill: #cfd8dc;
}

.cls-7 {
    fill: #cecab3;
}

.cls-8 {
    opacity: .73;
}

.cls-9 {
    fill: #bdbcbb;
}

.cls-10 {
    fill: #fff8d1;
}

.cls-11 {
    opacity: .13;
}

.cls-12 {
    opacity: .58;
}

.cls-13 {
    opacity: .38;
}

.cls-14 {
    fill: #544940;
}

.cls-15 {
    opacity: .99;
}

.cls-16 {
    fill: #fffdf8;
}

.cls-17 {
    fill: #706f67;
}

.cls-18 {
    fill: #d5d2af;
}

.cls-19 {
    fill: #cfc88a;
}

.cls-20 {
    fill: #bdb782;
}

.cls-21 {
    fill: #727167;
}

.cls-22 {
    opacity: .06;
}

.cls-23 {
    fill: #f4eba0;
}

.cls-24 {
    fill: #fff9d8;
}

.cls-25 {
    fill: #fdf4b0;
}

.cls-26 {
    opacity: .91;
}

.cls-27 {
    opacity: .19;
}

.cls-28 {
    fill: #fcf3a4;
}

.cls-29 {
    fill: #dfd790;
}

.cls-30 {
    fill: #fee0b1;
}

.cls-31 {
    fill: #a1877f;
}

.cls-32 {
    opacity: .82;
}

.cls-33 {
    fill: #a19d7b;
}

.cls-34 {
    opacity: .82;
}

.cls-35,
.cls-36 {
    fill: #fff;
}

.cls-37 {
    fill: #e9e2a5;
}

.cls-38 {
    fill: #fdf6c2;
}

.cls-39 {
    opacity: .23;
}

.cls-40 {
    opacity: .28;
}

.cls-41 {
    opacity: .22;
}

.cls-42 {
    fill: #7c7a6b;
}

.cls-43 {
    fill: #fffffd;
}

.cls-44 {
    fill: #fffcf2;
}

.cls-45 {
    fill: #fff7c8;
}

.cls-46 {
    fill: #1e1e1d;
}

.cls-47 {
    fill: #fdd835;
}

.cls-48 {
    fill: #fdf5b1;
}

.cls-49 {
    fill: #fff6ba;
}

.cls-50 {
    fill: #82806e;
}

.cls-51 {
    fill: #d4d1b0;
}

.cls-52 {
    fill: #dfdaac;
}

.cls-53 {
    fill: #e0d9ac;
}

.cls-54 {
    opacity: .64;
}

.cls-55 {
    fill: #dbd7ad;
}

.cls-56 {
    fill: #caae53;
}

.cls-57 {
    opacity: .02;
}

.cls-58 {
    fill: #989476;
}

.cls-59 {
    fill: #dad6ad;
}

.cls-60 {
    fill: #b1ac80;
}

.cls-61 {
    fill: #faf19e;
}

.cls-62 {
    fill: #fffdf5;
}

.cls-63 {
    fill: #fdf9d6;
}

.cls-64 {
    fill: #afbec5;
}

.cls-65 {
    opacity: .89;
}

.cls-66 {
    opacity: .15;
}

.cls-67 {
    opacity: .2;
}

.cls-68 {
    fill: #fdf3a7;
}

.cls-69 {
    opacity: .42;
}

.cls-70 {
    fill: #6b6a65;
}

.cls-71 {
    opacity: .25;
}

.cls-72 {
    fill: #bdbdbb;
}

.cls-73 {
    opacity: .29;
}

.cls-74 {
    opacity: .41;
}

.cls-75 {
    fill: #84826f;
}

.cls-76 {
    opacity: .5;
}

.cls-77 {
    fill: #e6e0a8;
}

.cls-78 {
    fill: #f1e9a3;
}

.cls-79 {
    fill: #f49719;
}

.cls-80 {
    opacity: .37;
}

.cls-81 {
    fill: #37474f;
}

.cls-82 {
    opacity: .58;
}

.cls-83 {
    opacity: .34;
}

.cls-84 {
    opacity: .39;
}

.cls-85 {
    fill: #7b7a6b;
}

.cls-86 {
    opacity: .95;
}

.cls-87 {
    fill: #f3eb98;
}

.cls-88 {
    fill: #d7cf8d;
}

.cls-89 {
    opacity: .11;
}

.cls-90 {
    fill: #fff7c6;
}

.cls-91 {
    opacity: .43;
}

.cls-92 {
    fill: #fdfae3;
}

.cls-93 {
    fill: #fdf4af;
}

.cls-94 {
    fill: #8b8871;
}

.cls-95 {
    fill: #dddcdb;
}

.cls-96 {
    opacity: .88;
}

.cls-97 {
    fill: #a6a27c;
}

.cls-98 {
    opacity: .8;
}

.cls-99 {
    opacity: .24;
}

.cls-100 {
    opacity: .61;
}

.cls-101 {
    fill: #c7c7e0;
}

.cls-102 {
    opacity: .95;
}

.cls-103 {
    fill: #fab650;
}

.cls-104 {
    opacity: .18;
}

.cls-105 {
    opacity: .94;
}

.cls-106 {
    fill: #646462;
}

.cls-107 {
    fill: #d0cdb2;
}

.cls-108 {
    opacity: .01;
}

.cls-109 {
    opacity: .69;
}

.cls-110 {
    fill: #455a64;
}

.cls-111 {
    opacity: .35;
}

.cls-112 {
    fill: #fbf29e;
}

.cls-113 {
    fill: #e5dc91;
}

.cls-114 {
    fill: #a8a37c;
}

.cls-115 {
    fill: #d5d1ae;
}

.cls-116 {
    fill: #cbc8b4;
}

.cls-117 {
    fill: #c4c3ba;
}

.cls-118 {
    opacity: .75;
}

.cls-119 {
    fill: #fec92a;
}

.cls-120 {
    opacity: .36;
}

.cls-121 {
    fill: #a4a07b;
}

.cls-122 {
    opacity: .9;
}

.cls-123 {
    opacity: .42;
}

.cls-124 {
    opacity: .47;
}

.cls-125 {
    fill: #b4b081;
}

.cls-126 {
    fill: #928f74;
}

.cls-127 {
    opacity: .44;
}

.cls-128 {
    fill: #4d4d4d;
}

.cls-129 {
    fill: #dfdaad;
}

.cls-130 {
    opacity: .71;
}

.cls-131 {
    opacity: .76;
}

.cls-132,
.cls-133,
.cls-134,
.cls-36,
.cls-135 {
    stroke: #1d1d1b;
    stroke-miterlimit: 10;
}

.cls-132,
.cls-134 {
    stroke-width: 2px;
}

.cls-132,
.cls-135 {
    fill: #9da6d4;
}

.cls-136 {
    opacity: .45;
}

.cls-137 {
    fill: #e0daac;
}

.cls-138 {
    opacity: .2;
}

.cls-139 {
    fill: #fffffc;
}

.cls-140 {
    fill: #c9c7b6;
}

.cls-141 {
    fill: #999677;
}

.cls-142 {
    fill: #fff8d0;
}

.cls-143 {
    fill: #fdf9d7;
}

.cls-144 {
    fill: #fffdf4;
}

.cls-145 {
    fill: #bdbebb;
}

.cls-146 {
    fill: #dcd48f;
}

.cls-147 {
    opacity: .33;
}

.cls-148 {
    fill: #8e6f64;
}

.cls-149 {
    fill: #cbc9b5;
}

.cls-150 {
    opacity: .79;
}

.cls-151 {
    opacity: .64;
}

.cls-152 {
    fill: #fdf5b3;
}

.cls-153 {
    fill: #bab482;
}

.cls-154 {
    fill: #e7e1a7;
}

.cls-155 {
    opacity: .6;
}

.cls-156 {
    opacity: 0;
}

.cls-157 {
    fill: #e7e1a5;
}

.cls-158 {
    fill: #ccc589;
}

.cls-159 {
    opacity: .66;
}

.cls-160 {
    fill: #c1c0ba;
}

.cls-161 {
    fill: #f9f19c;
}

.cls-162 {
    opacity: .68;
}

.cls-163 {
    fill: #0597a7;
}

.cls-164 {
    opacity: .3;
}

.cls-165 {
    opacity: .16;
}

.cls-166 {
    fill: #e4dea9;
}

.cls-167 {
    opacity: .8;
}

.cls-168 {
    fill: #fdf9d5;
}

.cls-169 {
    fill: #e0e0e0;
}

.cls-170 {
    fill: #5d4037;
}

.cls-171 {
    fill: #c9c6b6;
}

.cls-172 {
    fill: #e4dc91;
}

.cls-173 {
    opacity: .1;
}

.cls-174 {
    fill: #fdf7c4;
}

.cls-175 {
    fill: #fff8cf;
}

.cls-176 {
    opacity: .53;
}

.cls-177 {
    opacity: .57;
}

.cls-178 {
    opacity: .08;
}

.cls-179 {
    fill: #f6eda0;
}

.cls-180 {
    opacity: .81;
}

.cls-181 {
    opacity: .92;
}

.cls-182 {
    opacity: .98;
}

.cls-183 {
    fill: #fffffb;
}

.cls-184 {
    opacity: .56;
}

.cls-185 {
    opacity: .52;
}

.cls-186 {
    opacity: .77;
}

.cls-187 {
    opacity: .03;
}

.cls-188 {
    opacity: .72;
}

.cls-189 {
    opacity: .19;
}

.cls-190 {
    fill: #f1e997;
}

.cls-191 {
    opacity: .67;
}

.cls-192 {
    opacity: .86;
}

.cls-193 {
    fill: #ede496;
}

.cls-194 {
    fill: #faf19f;
}

.cls-195 {
    opacity: .44;
}

.cls-196 {
    fill: #c5bf86;
}

.cls-197 {
    fill: #c1c0b9;
}

.cls-198 {
    fill: #efe8a4;
}

.cls-199 {
    opacity: .32;
}

.cls-200 {
    fill: #fffadd;
}

.cls-201 {
    fill: #fffcf1;
}

.cls-202 {
    fill: #fdf6bc;
}

.cls-203 {
    fill: #d7d3af;
}

.cls-204 {
    fill: #fff8cc;
}

.cls-205 {
    fill: #f4eca1;
}

.cls-206 {
    opacity: .69;
}

.cls-207 {
    fill: #c0bfba;
}

.cls-208 {
    fill: #cecbb3;
}

.cls-209 {
    fill: #f1e897;
}

.cls-210 {
    fill: #fdf6bf;
}

.cls-133,
.cls-134 {
    fill: none;
}

.cls-211 {
    opacity: .62;
}

.cls-212 {
    opacity: .26;
}

.cls-213 {
    opacity: .33;
}

.cls-214 {
    opacity: .64;
}

.cls-215 {
    opacity: .56;
}

.cls-216 {
    fill: #fffbea;
}

.cls-217 {
    fill: #fbf29d;
}

.cls-218 {
    opacity: .48;
}

.cls-219 {
    fill: #fffcee;
}

.cls-220 {
    opacity: .07;
}

.cls-221 {
    fill: #fff8d3;
}

.cls-222 {
    opacity: .44;
}

.cls-223 {
    fill: #d1ca8b;
}

.cls-224 {
    opacity: .94;
}

.cls-225 {
    opacity: .87;
}

.cls-226 {
    opacity: .86;
}

.cls-227 {
    opacity: .59;
}

.cls-228 {
    fill: #d9d5af;
}

.cls-229 {
    fill: #aca77e;
}

.cls-230 {
    fill: #6f6e67;
}

.cls-231 {
    fill: #fffbe6;
}

.cls-232 {
    opacity: .52;
}

.cls-233 {
    fill: #fffbe5;
}

.cls-234 {
    fill: #fdf6ba;
}

.cls-235 {
    opacity: .14;
}

.cls-236 {
    fill: #555455;
}

.cls-237 {
    fill: #e8e093;
}

.cls-238 {
    fill: #fdf4ae;
}

.cls-239 {
    opacity: .31;
}

.cls-240 {
    fill: #fff6b9;
}

.cls-241 {
    fill: #f2eaa2;
}

.cls-242 {
    fill: #fffadc;
}

.cls-243 {
    fill: #fffadf;
}

.cls-244 {
    fill: #fbf19d;
}

.cls-245 {
    opacity: .27;
}

.cls-246 {
    opacity: .45;
}

.cls-247 {
    opacity: .84;
}

.cls-248 {
    opacity: .53;
}

.cls-249 {
    opacity: .81;
}

.cls-250 {
    fill: #b8b281;
}

.cls-251 {
    fill: #b3ad80;
}

.cls-252 {
    fill: #e6e0a7;
}

.cls-253 {
    opacity: .17;
}

.cls-254 {
    opacity: .47;
}

.cls-255 {
    opacity: .28;
}

.cls-256 {
    opacity: .08;
}

.cls-257 {
    opacity: .61;
}

.cls-258 {
    fill: #656563;
}

.cls-259 {
    opacity: .72;
}

.cls-260 {
    opacity: .03;
}

.cls-261 {
    fill: #939075;
}

.cls-262 {
    opacity: .22;
}

.cls-263 {
    fill: #e6dfa8;
}

.cls-264 {
    opacity: .19;
}

.cls-265 {
    fill: #ebe4a5;
}

.cls-266 {
    opacity: .97;
}

.cls-267 {
    fill: #f8ef9a;
}

.cls-268 {
    fill: #fdf3a5;
}

.cls-269 {
    opacity: .36;
}

.cls-270 {
    fill: #949ecf;
}

.cls-271 {
    fill: #e2da91;
}

.cls-272 {
    opacity: .67;
}

.cls-273 {
    opacity: .34;
}

.cls-274 {
    fill: #686965;
}

.cls-275 {
    fill: #e4dea8;
}

.cls-276 {
    fill: #fff9da;
}

.cls-277 {
    opacity: .17;
}

.cls-278 {
    opacity: .57;
}

.cls-279 {
    opacity: .94;
}

.cls-280 {
    fill: #d5d1af;
}

.cls-281 {
    opacity: .75;
}

.cls-282 {
    fill: #7f7c6c;
}

.cls-283 {
    opacity: .78;
}

.cls-284 {
    fill: #90a4ae;
}

.cls-285 {
    opacity: .27;
}

.cls-286 {
    fill: #dadada;
}

.cls-287 {
    opacity: .06;
}

.cls-288 {
    fill: #807e6d;
}

.cls-289 {
    opacity: .7;
}

.cls-290 {
    fill: #eae3a5;
}

.cls-291 {
    opacity: .54;
}

.cls-292 {
    opacity: .49;
}

.cls-293 {
    opacity: .88;
}

.cls-294 {
    opacity: .49;
}

.cls-295 {
    fill: #9d9978;
}

.cls-296 {
    fill: #8c8a72;
}

.cls-297 {
    fill: #ee6f49;
}

.cls-298 {
    fill: #efe8a3;
}

.cls-299 {
    fill: #eee6a4;
}

.cls-300 {
    fill: #ffec00;
}

.cls-301 {
    opacity: .65;
}

.cls-302 {
    fill: #c1bb85;
}

.cls-303 {
    opacity: .11;
}

.cls-304 {
    fill: #fdfae2;
}

.cls-305 {
    opacity: .74;
}

.cls-306 {
    fill: #424242;
}

.cls-307 {
    fill: #d1ceb1;
}

.cls-308 {
    fill: #fdf6c1;
}

.cls-309 {
    opacity: .16;
}

.cls-310 {
    fill: #fdf4aa;
}

.cls-311 {
    fill: #fdf7cc;
}

.cls-312 {
    opacity: .1;
}

.cls-313 {
    fill: #666663;
}

.cls-314 {
    fill: #fffbe8;
}

.cls-315 {
    fill: #e0d890;
}

.cls-316 {
    fill: #79776a;
}

.cls-317 {
    fill: #c4c3b8;
}

.cls-318 {
    fill: #807f6d;
}

.cls-319 {
    opacity: .9;
}

.cls-320 {
    fill: #e7de92;
}

.cls-321 {
    fill: #cfcbb3;
}

.cls-322 {
    opacity: .83;
}

.cls-323 {
    fill: #c2c1b9;
}

.cls-324 {
    fill: #b49a88;
    opacity: .3;
}

.cls-325 {
    opacity: .09;
}

.cls-326 {
    opacity: .78;
}

.cls-327 {
    fill: #fff8cd;
}

.cls-328 {
    fill: #fdf3a8;
}

.cls-329 {
    opacity: .83;
}

.cls-330 {
    fill: #39a935;
}

.cls-331 {
    fill: #ccc9b4;
}

.cls-332 {
    fill: #dcd7ac;
}

.cls-333 {
    fill: #d4cd8c;
}

.cls-334 {
    fill: #c2bd86;
}

.cls-335 {
    opacity: .55;
}

.cls-336 {
    opacity: .83;
}

.cls-337 {
    fill: #f9ef9f;
}

.cls-338 {
    fill: #fdf3a0;
}

.cls-339 {
    opacity: .51;
}

.cls-340 {
    fill: #617d8b;
}

.cls-341 {
    fill: #c9c388;
}

.cls-342 {
    fill: #d2cdb2;
}

.cls-343 {
    fill: #c5c4b8;
}

.cls-344 {
    fill: #a9a57d;
}

.cls-345 {
    fill: #76756a;
}

.cls-346 {
    opacity: .56;
}

.cls-347 {
    fill: #fdf7c5;
}

.cls-348 {
    fill: #e1dcaa;
}

.cls-349 {
    fill: #01838f;
}

.cls-350 {
    fill: #fdf39f;
}

.cls-351 {
    fill: #ed6c0c;
}

.cls-352 {
    fill: #626261;
}

.cls-353 {
    opacity: .7;
}

.cls-354 {
    opacity: .43;
}

.cls-355 {
    opacity: .1;
}

.cls-356 {
    opacity: .4;
}

.cls-357 {
    fill: #bdbcbc;
}

.cls-358 {
    fill: #747268;
}

.cls-359 {
    opacity: .55;
}

.cls-360 {
    opacity: .66;
}

.cls-361 {
    opacity: .33;
}

.cls-362 {
    opacity: .63;
}

.cls-363 {
    opacity: .85;
}

.cls-364 {
    fill: #ebe3a5;
}

.cls-365 {
    opacity: .99;
}

.cls-366 {
    opacity: .48;
}

.cls-367 {
    fill: #787669;
}

.cls-368 {
    fill: #ef7c0f;
}

.cls-369 {
    opacity: .92;
}

.cls-370 {
    fill: #788f9b;
}

.cls-371 {
    opacity: .32;
}

.cls-372 {
    fill: #cdcab4;
}

.cls-373 {
    fill: #bfbdbb;
}

.cls-374 {
    fill: #bfbfba;
}

.cls-375 {
    fill: #fdf4a9;
}

.cls-376 {
    fill: #e95226;
}

.cls-377 {
    fill: #cccab4;
}

.cls-378 {
    fill: #202020;
}

.cls-379 {
    fill: #d3ceb0;
}

.cls-380 {
    fill: #c6c4b7;
}

.cls-381 {
    opacity: .14;
}

.cls-382 {
    fill: #86836f;
}

.cls-383 {
    opacity: .47;
}

.cls-384 {
    fill: #ea592d;
}

.cls-385 {
    fill: #fffdf6;
}

.cls-386 {
    opacity: .04;
}

.cls-387 {
    opacity: .2;
}

.cls-388 {
    fill: #e5dfa8;
}

.cls-389 {
    opacity: .93;
}

.cls-390 {
    fill: #ebe294;
}

.cls-391 {
    fill: #fff5b8;
}

.cls-392 {
    fill: #f8f09e;
}

.cls-393 {
    opacity: .98;
}

.cls-394 {
    opacity: .14;
}

.cls-395 {
    fill: #fff9db;
}

.cls-396 {
    fill: #fffcef;
}

.cls-397 {
    opacity: .39;
}

.cls-398 {
    opacity: .36;
}

.cls-399 {
    fill: #aaa77e;
}

.cls-400 {
    opacity: .21;
}

.cls-401 {
    opacity: .51;
}

.cls-402 {
    opacity: .26;
}

.cls-403 {
    opacity: .5;
}

.cls-404 {
    fill: #fdf5b4;
}

.cls-405 {
    opacity: .39;
}

.cls-406 {
    fill: #f5eca0;
}

.cls-407 {
    fill: #c7c5b7;
}

.cls-408 {
    opacity: .84;
}

.cls-409 {
    fill: #757575;
}

.cls-410 {
    fill: #cec78a;
}

.cls-411 {
    opacity: .58;
}

.cls-412 {
    fill: #f7ee9f;
}

.cls-413 {
    opacity: .92;
}

.cls-414 {
    opacity: .78;
}

.cls-415 {
    opacity: .76;
}

.cls-416 {
    fill: #bcb782;
}

.cls-417 {
    opacity: .17;
}

.cls-418 {
    opacity: .18;
}

.cls-419 {
    opacity: .91;
}

.cls-420 {
    opacity: .6;
}

.cls-421 {
    opacity: .73;
}

.cls-422 {
    opacity: .09;
}

.cls-423 {
    opacity: .22;
}

.cls-424 {
    opacity: .07;
}

.cls-425 {
    fill: #fffdf9;
}

.cls-426 {
    fill: #eae294;
}

.cls-427 {
    fill: #898771;
}

.cls-428 {
    opacity: .28;
}

.cls-429 {
    fill: #fdf3a1;
}

.cls-430 {
    opacity: .5;
}

.cls-431 {
    opacity: .31;
}

.cls-432 {
    fill: #ffec3d;
}

.cls-433 {
    fill: #d7d08e;
}

.cls-434 {
    fill: #ede6a5;
}

.cls-435 {
    opacity: .11;
}

.cls-436 {
    opacity: .08;
}

.cls-437 {
    fill: #fdf3a6;
}

.cls-438 {
    fill: #b6b181;
}

.cls-439 {
    opacity: .4;
}

.cls-440 {
    opacity: .06;
}

.cls-441 {
    fill: #ebe5a5;
}

.cls-442 {
    fill: #dad28e;
}

.cls-443 {
    fill: #795649;
}

.cls-444 {
    fill: #f5ed99;
}

.cls-445 {
    opacity: .03;
}

.cls-446 {
    opacity: .38;
}

.cls-447 {
    opacity: .89;
}

.cls-448 {
    fill: #fff7c7;
}

.cls-449 {
    fill: #d3cc8c;
}

.cls-450 {
    fill: #e8e1a7;
}

.cls-451 {
    opacity: .97;
}

.cls-452 {
    fill: #fdf5b7;
}

.cls-453 {
    opacity: .3;
}

.cls-454 {
    fill: #ddd8ac;
}

.cls-455 {
    fill: #fdf6bd;
}

.cls-456 {
    fill: #ffe082;
}

.cls-457 {
    fill: #888570;
}

.cls-458 {
    fill: #e5dea8;
}

.cls-459 {
    opacity: .67;
}

.cls-460 {
    fill: #eee8a5;
}

.cls-461 {
    fill: #cac7b6;
}

.cls-462 {
    fill: #e3ddaa;
}

.cls-463 {
    fill: #fffceb;
}

.cls-464 {
    opacity: .31;
}

.cls-465 {
    opacity: .85;
}

.cls-466 {
    fill: #aeaa7e;
}

.cls-467 {
    fill: #8f8c73;
}

.cls-468 {
    fill: #9f9b7a;
}

.cls-469 {
    fill: #fffcec;
}

.cls-470 {
    fill: #a29e7a;
}

.cls-471 {
    opacity: .61;
}

.cls-472 {
    fill: #9e9e9e;
}

.cls-473 {
    fill: #eee;
}

.cls-474 {
    fill: #cac387;
}

.cls-475 {
    fill: #686868;
}

.cls-476 {
    fill: #e2dcab;
}

.cls-477 {
    fill: #6d6c66;
}

.cls-478 {
    fill: #fcf29f;
}

.cls-479 {
    fill: #fffdf3;
}

.cls-480 {
    opacity: .68;
}

.cls-481 {
    opacity: .12;
}

.cls-482 {
    fill: #fffae1;
}

.cls-483 {
    opacity: .46;
}

.cls-484 {
    opacity: .35;
}

.cls-485 {
    opacity: .25;
}

.cls-486 {
    fill: #9b9878;
}

.cls-487 {
    opacity: .05;
}

.cls-488 {
    opacity: .12;
}

.cls-489 {
    opacity: .97;
}

.cls-490 {
    opacity: .23;
}

.cls-491 {
    opacity: .24;
}

.cls-492 {
    fill: #bfb983;
}

.cls-493 {
    opacity: .93;
}

.cls-494 {
    fill: #f1ceb4;
}

.cls-495 {
    fill: #fdf7cb;
}

.cls-496 {
    fill: #fdf4ab;
}

.cls-497 {
    fill: #f9f0a0;
}

.cls-498 {
    fill: #8f8d73;
}

.cls-499 {
    fill: #1d1d1b;
}

.cls-500 {
    opacity: .77;
}

.cls-501 {
    opacity: .59;
}

.cls-502 {
    opacity: .74;
}

.cls-503 {
    opacity: .96;
}

.cls-504 {
    fill: #c4bd85;
}

.cls-505 {
    fill: #d8d3af;
}

.cls-506 {
    opacity: .69;
}

.cls-507 {
    opacity: .65;
}

.cls-508 {
    fill: #d4d0b0;
}

.cls-509 {
    fill: #f8ef9f;
}

.cls-510 {
    fill: #dad6af;
}

.cls-511 {
    opacity: .81;
}

.cls-512 {
    opacity: .15;
}

.cls-513 {
    opacity: .05;
}

.cls-514 {
    opacity: .02;
}

.cls-515 {
    fill: #c5c3b8;
}

.cls-516 {
    fill: #fffdf7;
}

.cls-517 {
    opacity: .86;
}

.cls-518 {
    opacity: .62;
}

.cls-519 {
    fill: #e5007e;
}

.cls-520 {
    fill: #fdf7c3;
}

.cls-521 {
    opacity: .42;
}

.cls-522 {
    fill: #fffae0;
}

.cls-523 {
    fill: #f7ed99;
}

.cls-524 {
    opacity: .53;
}

.cls-525 {
    opacity: .41;
}

.cls-526 {
    fill: #fffbe9;
}

.cls-527 {
    fill: #959175;
}

